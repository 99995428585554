/* @font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
 */

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  min-width: 320px;
  max-width: 100vw;
  --h-waves: 20vh;
  --azul-escuro: #000063;
  --azul-claro: #00318d;
  /*002D81*/
  --laranja-escuro: #fb9237;
  --laranja-claro: #ffa454;
  /*FF6400*/
  --verde: #25d366;
  --branco: #fbfbfb
}

body {
  background-color: var(--branco);
  background-position: top center;
  background-size: cover;
  max-width: 100%;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 5;
  overflow-x: hidden;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}


.text-black {
  color: #000000;
}

.texto {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin: 8px 12px;
}

button:active {
  cursor: pointer;
  position: relative;
  top: 2px;
}

.fab:hover {
  background-color: #1dae52;
}

.btn:active {
  position: relative;
  top: 1px;
}


.btn-azul:hover {
  background: linear-gradient(to left, var(--azul-escuro) 70%, var(--azul-claro) 100%);
  background-color: #0065a8;
  transform: scale(1.1);
  animation: none;
  transition: all 0.5s;
}




.btn-laranja {
  background: linear-gradient(to left, var(--laranja-escuro) 30%, var(--laranja-claro) 100%);
  background-color: var(--laranja-escuro);
  border: 1px solid var(--laranja-escuro);
}

.btn-laranja:hover {
  background: linear-gradient(to left, var(--laranja-escuro) 30%, var(--laranja-claro) 100%);
  background-color: var(--laranja-escuro);
  transform: scale(1.1);
  animation: none;
  transition: all 0.5s;
}

/*IE9*/
*::selection {
  background-color: transparent;
}

*::-moz-selection {
  background-color: transparent;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  transition: all .2s;
}

*,
.btn-brush,
.btn-brush img {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  /*IE10*/
  -ms-user-select: none;
  user-select: none;

  /*You just need this if you are only concerned with android and not desktop browsers.*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h3, h4, p {
  margin: 4px;
}

.text-1 {
  font-size: 1rem;
}

/* ========= PQ ATN ========= */
.pq-container {
  position: relative;
  padding: 72px 0;
}

.pq-container .title {
  font-size: 2.5rem;
  margin-top: -8px;
}

.logo-fundo {
  position: absolute;
  left: -32px;
  top: 0;
  z-index: -1;
  height: 100%;
}

.logo-fundo-container {
  height: 100%;
  max-width: 600px;
  min-height: 240px;
  filter: opacity(0.1);
  aspect-ratio: 413/556;
}

.video {
  width: 35%;
  min-width: 300px;
  /* margin: 0 0 0 -87px; */
  flex-grow: 1;

}

.video video {
  border-radius: 25px;
  border: 4px solid var(--laranja-escuro);
  overflow: hidden;
}

.motivos-wrap {
  max-width: 900px;
  width: 100%;
}

.motivo-container {
  /* align-items: flex-start; */

}

.motivo-title {
  font-size: 1.5rem;
}

.motivo-icon-container {
  width: 40%;
  min-width: 200px;
  max-width: 360px;
  margin-right: -25%;
  margin-left: -10%;
  position: relative;
  align-self: flex-start;
}

.bubble-bg path {
  fill: var(--laranja-escuro);

}

.motivo-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  z-index: 0;
}

.motivo-icon svg {
  width: 70px;
  height: 70px;
  z-index: 1;
  margin-left: -5%;
}

.motivo-container--left {
  margin-left: -5%;
  width: 110%;
  /* margin-right: 10%; */
  flex-direction: row-reverse;
}

.text-wrap {
  z-index: 2;
  width: 60%;
  min-width: 250px;
}

.motivo-container--left .text-wrap {
  margin-left: 12%;
  flex-grow: 1;
}


/* =========== DEPOIMENTOS ====== */
.container {
  padding: 24px;
}

.depo-container {
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  background: linear-gradient(160deg, var(--laranja-claro) 41%, var(--laranja-escuro) 100%);
}

.onda {
  min-width: 320px;
  margin-top: -12px;
  aspect-ratio: 59/9;
  max-height: 100px;
  object-fit: contain;
  width: 100%;
}

.depo-container .onda path {
  fill: var(--branco);

}

.slider-container {
  min-height: 50vh;
  position: relative;
  margin: 24px 0;
}

.full-slider {
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  margin-left: -24px;
}

#slider-clientes {
  position: relative;
  height: 100%;
}

.glide__arrow {
  position: absolute !important;
  top: calc(50% - 36px) !important;
  background-color: transparent;
  border: none;
  height: 72px;
  cursor: pointer;
  max-width: 72px;
}

.glide__arrow--right {
  right: 0;
}

.glide__arrows .glide__arrow--right svg,
.glide__arrows .glide__arrow--left svg {
  height: 100%;
  width: 100%;
  min-width: unset;
  margin: 0;
  fill: none;
}

.glide__arrows .glide__arrow path {
  fill: none;
}

.glide__arrows .glide__arrow polyline {
  filter: drop-shadow(0px 1px 1px rgba(69, 69, 69, 0.3));
  stroke: var(--laranja-claro);
  stroke-width: 4px;
  transition: all .1s;
}

.glide__arrows .glide__arrow:hover polyline {
  filter: drop-shadow(0px 1px 1px rgba(69, 69, 69, 0.5));
  stroke: var(--laranja-escuro);
  transition: all .3s;
  transform: scale(1.02) translate(-2px);
}

@media only screen and (max-width: 600px) {
  #btn-2{
    align-self: center;
  }

  .depo-item {
    filter: blur(2px);
    transition: all .3s;
  }

  .depo-item.glide__slide--active {
    transition: all .01s;
    filter: blur(0);
  }
}

.item-projetos {
  display: flex;
  justify-content: center;
}

.item-projetos article {
  max-width: 90%;
}

.depo-item img {
  /* height: 50vh; */
  /* max-height: 500px; */
  max-width: 326px;
  aspect-ratio: 140/243;
  position: relative;
}

.glide__slides {
  /* max-height: 50vh; */
}

/* ========= ESPECIALIDADES ======= */
.item-especialista-wrap {}

.especialista-title {
  font-weight: 800 !important;
}

.especialista-title span {
  text-transform: uppercase;
}

.especialista-img {
  height: 250px;
  width: 270px;
  border-radius: 30%;
  border: 4px var(--laranja-escuro) solid;
  overflow: hidden;
  background-color: var(--laranja-escuro);
}

.depo-item,
.especialista-img {
  transform: scale(.95);
  transition: all 0.3s;
}

.glide__slide--active.depo-item,
.glide__slide--active .especialista-img,
.especialista-img:hover {
  transform: scale(1);
  transition: all 0.3s;
}

.especialista-img img {
  aspect-ratio: 131/121;
  object-fit: cover;
  filter: opacity(.8);
}

.text-1 {
  font-size: 1.2rem;
  font-weight: 400;
}

.text-2 {
  font-size: 1rem;
  font-weight: 400;
}

.text-4 {
  font-size: 0.8rem;
  font-weight: 400;
}

/* ========== CTA FINAL =============== */
.cta--wrap {
  padding: 32px;
  position: relative;
}

.cta--wrap .logo-fundo {
  left: unset;
  right: 24px;
  transform: rotate(-180deg);
  max-height: 300px;
  bottom: -56px;
  top: unset;
}

.full-section {
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  margin-left: -32px;
  background: linear-gradient(160deg, var(--laranja-escuro) 61%, var(--laranja-claro) 100%);
  padding: 24px;
}

.logo-footer {
  width: 150px;
}

.title {}

.text-light {
  font-weight: 300 !important;
}

.rede-item {
  width: 48px;
  height: 48px;
  margin: 8px;
  transition: all .3s;
}

.rede-item:hover {
  transform: scale(1.1);
  transition: all .3s;
}

footer {
  background-color: var(--azul-escuro);
  padding: 12px;
  margin-top: -12px;
  text-align: center;
}


/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 1.25dppx),
(min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {

  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " ("attr(href) ")";
  }

  abbr[title]::after {
    content: " ("attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}


.logo-wrap {
  width: 108vw;
  min-height: 160px;
  max-width: 700px;
  min-width: 200px;
  margin-top: -46px;
}

[data-sal] {
  transition-delay: 0s;
  transition-delay: var(0s);
  transition-duration: .2s;
  transition-duration: var(.2s);
  transition-timing-function: ease;
  transition-timing-function: var(ease)
}

[data-sal][data-sal-delay="100"] {
  transition-delay: .1s
}

[data-sal|=slide] {
  opacity: 0;
  transition-property: opacity, transform
}

[data-sal=slide-up] {
  transform: translateY(20%)
}

[data-sal=slide-left] {
  transform: translateX(20%)
}

[data-sal=slide-right] {
  transform: translateX(-20%)
}

[data-sal|=slide].sal-animate, body.sal-disabled [data-sal|=slide] {
  opacity: 1;
  transform: none
}

div {
  box-sizing: border-box;
}

input[type="text"],
textarea,
[contenteditable] {

  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.scale-up-ver-top {
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.scale-in-ver-bottom {
  -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.shake-bottom {
  -webkit-animation: shake-bottom 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: shake-bottom 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.animated.scale-up-ver-top {
  -webkit-animation: scale-up-ver-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.heartbeat {
  -webkit-animation: heartbeat 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: heartbeat 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

@keyframes spin {

  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) rotate(1080deg) scale(1);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }

  98% {
    -webkit-transform: translate(-50%, -50%) rotate(1080deg) scale(1);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(1080deg) scale(0);
  }
}

@keyframes success {
  from {
    transform: translate(-50%, -50%) rotate(0) scale(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
  }
}

@-webkit-keyframes success {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0) scale(0);
  }

  to {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg) scale(1);
  }
}

@keyframes error {
  from {
    transform: translate(-50%, -50%) scale(0);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    background-color: #f44336;
  }
}

@-webkit-keyframes error {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
  }

  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    background-color: #f44336;
  }
}


@keyframes vibrate {

  0%,
  30%,
  60%,
  85%,
  100% {
    left: 0;
    background-color: #f44336;
  }

  10%,
  40%,
  90%,
  70% {
    left: -2px;
    background-color: #f44336;
  }

  20%,
  50%,
  80%,
  95% {
    left: 2px;
    background-color: #f44336;
  }
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes shake-bottom {

  0%,
  50%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  55% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  60%,
  70%,
  80% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  65%,
  85%,
  75% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  90% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  95% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes shake-bottom {

  0%,
  50%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  55% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  60%,
  70%,
  80% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  65%,
  85%,
  75% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  90% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  95% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes scale-in-ver-bottom {
  0% {
    /* -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%; */
    height: 0;
    overflow: hidden;
    opacity: 1;
    ;
  }

  60% {

    -webkit-box-shadow: 0px 0px 0px 0px #df9b00;
    box-shadow: 0px 0px 0px 0px #df9b00;
    -moz-box-shadow: 0px 0px 0px 0px #df9b00;
  }

  100% {
    /* -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%; */
    height: 100%;
    overflow: hidden;
    opacity: 1;
    -webkit-box-shadow: -5px 7px 0px 0px #df9b00;
    box-shadow: -5px 7px 0px 0px #df9b00;
    -moz-box-shadow: -5px 7px 0px 0px #df9b00;
  }


  /* 100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
      } */
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}


@-webkit-keyframes shake-horizontal {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@keyframes shake-horizontal {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}